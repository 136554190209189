/*
 * 合同相关接口列表
 */

import axios from '@/utils/http'; // 导入http中创建的axios实例
//合同申请接口
export const insertContractApply = (param) => {
  return axios.post(`/wlapi/hyContractApply/insertContractApply`, param);
}
//合同审批列表
export const selectContractApplyByPagination = (param) => {
  return axios.post(`/wlapi/hyContractApply/selectContractApplyByPagination`, param);
}
//终止
export const updateEndByApplyId = (param) => {
  return axios.post(`/wlapi/hyContractApply/updateEndByApplyId`, param);
}
//合同详情
export const contractSelectApplyById = (param) => {
  return axios.get(`/wlapi/hyContractApply/selectApplyById?id=${param}`);
}
//项目合同查询
export const selectProjectContractByParam = (param) => {
  return axios.post(`/wlapi/hyProjectContract/selectProjectContractByParam`, param);
}
//项目组成员
export const selectTeamMember = (param) => {
  return axios.post(`/wlapi/hyContractApply/selectTeamMember`, param);
}
//合同审批
export const updateToContractApproval = (param) => {
  return axios.post(`/wlapi/hyContractApply/updateToContractApproval`, param);
}
//合同修改
export const updateContractApply = (param) => {
  return axios.post(`/wlapi/hyContractApply/updateContractApply`, param);
}
//合同修订
export const updateReviseApply = (param) => {
  return axios.post(`/wlapi/hyContractApply/updateReviseApply`, param);
}
//修改项目组成员
export const updateTeamByApplyId = (param) => {
  return axios.post(`/wlapi/hyContractApply/updateTeamByApplyId`, param);
}
//合同查询用于付款变更结算
export const selectContractByParam = (param) => {
  return axios.post(`/wlapi/hyContractApply/selectContractByParam`, param);
}
//查询申请内付款节点
export const selectNextPaymentNode = (param) => {
  return axios.post(`/personnelapi/hyPayPlan/selectNextPaymentNode`, param);
}
//付款申请
export const insertPaymentApply = (param) => {
  return axios.post(`/personnelapi/hyPaymentApply/insertPaymentApply`, param);
}
//付款申请列表
export const selectPaymentApplyByPagination = (param) => {
  return axios.post(`/personnelapi/hyPaymentApply/selectPaymentApplyByPagination`, param);
}
//付款详情
export const selectPaymentApplyById = (param) => {
  return axios.get(`/personnelapi/hyPaymentApply/selectApplyById?id=${param}`);
}
//付款审批
export const updateToApproval = (param) => {
  return axios.post(`/personnelapi/hyPaymentApply/updateToApproval`, param);
}
//修改付款详情
export const updatePaymentApply = (param) => {
  return axios.post(`/personnelapi/hyPaymentApply/updatePaymentApply`, param);
}
//修订付款详情
export const paymentUpdateReviseApply = (param) => {
  return axios.post(`/personnelapi/hyPaymentApply/updateReviseApply`, param);
}
//付款支付反馈
export const insertFeedback = (param) => {
  return axios.post(`/personnelapi/hyPaymentApply/insertFeedback`, param);
}
//变更审批列表
export const selectChangeApplyByPagination = (param) => {
  return axios.post(`/wlapi/hyChangeApply/selectChangeApplyByPagination`, param);
}
//变更审请
export const insertChangeApply = (param) => {
  return axios.post(`/wlapi/hyChangeApply/insertChangeApply`, param);
}
//变更终止
export const changeUpdateEndByApplyId = (param) => {
  return axios.post(`/wlapi/hyChangeApply/updateEndByApplyId`, param);
}
//变更审批
export const updateToChangeApproval = (param) => {
  return axios.post(`/wlapi/hyChangeApply/updateToChangeApproval`, param);
}
//变更详情
export const changeSelectApplyById = (param) => {
  return axios.get(`/wlapi/hyChangeApply/selectApplyById?id=${param}`);
}
//变更修改
export const updateChangeApply = (param) => {
  return axios.post(`/wlapi/hyChangeApply/updateChangeApply`, param);
}
//变更修订
export const changeUpdateReviseApply = (param) => {
  return axios.post(`/wlapi/hyChangeApply/updateReviseApply`, param);
}
//查询结算申请内付款节点
export const selectSettlementNode = (param) => {
  return axios.post(`/personnelapi/hyPayRecord/selectSettlementNode`, param);
}
//查询合同所涉及到的所有专业
export const selectAllContractProfessional = (param) => {
  return axios.post(`/personnelapi/hySettlementApply/selectAllContractProfessional`, param);
}
//结算申请
export const insertSettlementApply = (param) => {
  return axios.post(`/personnelapi/hySettlementApply/insertSettlementApply`, param);
}
//结算列表
export const selectSettlementApplyByPagination = (param) => {
  return axios.post(`/personnelapi/hySettlementApply/selectSettlementApplyByPagination`, param);
}
//结算审批
export const setlemntUpdateToApproval = (param) => {
  return axios.post(`/personnelapi/hySettlementApply/updateToApproval`, param);
}
//结算修改
export const updateSettlementApply = (param) => {
  return axios.post(`/personnelapi/hySettlementApply/updateSettlementApply`, param);
}
//结算详情
export const setlemntSelectApplyById = (param) => {
  return axios.get(`/personnelapi/hySettlementApply/selectApplyById?id=${param}`);
}
//结算修订
export const setlemntUpdateReviseApply = (param) => {
  return axios.post(`/personnelapi/hySettlementApply/updateReviseApply`, param);
}
//结算反馈
export const setlemntInsertFeedback = (param) => {
  return axios.post(`/personnelapi/hySettlementApply/insertFeedback`, param);
}
//支付记录
export const selectPayRecordByPagination = (param) => {
  return axios.post(`/wlapi/hyPayRecord/selectPayRecordByPagination`, param);
}
//全部支付记录
export const selectPayRecordByAll = (param) => {
  return axios.post(`/wlapi/hyPayRecord/selectPayRecordByAll`, param);
}
//合同类型
export const selectContractTypeByParam = (param) => {
  return axios.post(`/wlapi/knContractType/selectContractTypeByParam`, param);
}
//新增合同类型
export const insertContractType = (param) => {
  return axios.post(`/wlapi/knContractType/insertContractType`, param);
}
//删除合同类型
export const deleteContractTypeByParam = (param) => {
  return axios.post(`/wlapi/knContractType/deleteContractTypeByParam`, param);
}
//系统生成变更单号
export const sysgenChangeNo = (param) => {
  return axios.post(`/personnelapi/hyChangeApply/sysgenChangeNo`, param);
}
// 查询投标类别列表
export const selectBidType = (param) => {
  return axios.post(`/personnelapi/knBidType/selectBidType`, param);
}
// 添加或修改投标类别  
export const addBidType = (param) => {
  return axios.post(`/personnelapi/knBidType/addBidType`, param);
}
// 删除投标类别 
export const deleteBidType = (param) => {
  return axios.post(`/personnelapi/knBidType/deleteBidType`, param);
}
// 分页查询投标申请列表 
export const selectBidApplyByPagination = (param) => {
  return axios.post(`/personnelapi/knBidApply/selectBidApplyByPagination`, param);
}
// 发起投标申请 
export const insertBidApply = (param) => {
  return axios.post(`/personnelapi/knBidApply/insertBidApply`, param);
}
//投标详情
export const selectBidApplyById = (param) => {
  return axios.post(`/personnelapi/knBidApply/selectBidApplyById`, param);
}
//审批投标申请
export const updateToBidApply = (param) => {
  return axios.post(`/personnelapi/knBidApply/updateToBidApply`, param);
}
//修改投标申请
export const updateBidApply = (param) => {
  return axios.post(`/personnelapi/knBidApply/updateBidApply`, param);
}
//暂停或启动投标申请
export const setPauseOrStart = (param) => {
  return axios.post(`/personnelapi/knBidApply/setPauseOrStart`, param);
}