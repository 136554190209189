import {
  computed,
  reactive,
  toRefs
} from 'vue'

function useTablePagination() {
  const paginationData = reactive({
    pageSize: 10,
    current: 1,
    total: 0,
  })
  const defaultConfig = {
    position: 'bottom',
    showSizeChanger: true,
    showQuickJumper: true,
    pageSizeOptions: ['10', '20', '50'],
    showTotal: (total, range) => `总共${total}条`
  }
  const handlePaginationChange = (page, pageSize) => {
    paginationData.current = page;
    paginationData.pageSize = pageSize
  }
  const paginationConfig = computed(() => ({
    ...defaultConfig,
    total: paginationData.total,
    current: paginationData.current,
    pageSize: paginationData.pageSize,
    onChang: handlePaginationChange,
    onShowSizeChange: handlePaginationChange
  }))
  return {
    paginationConfig,
    ...toRefs(paginationData)
  }
}

export default useTablePagination