import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import { defineComponent, ref, reactive, computed, watch, onMounted } from 'vue';
import { ProfileOutlined, BarsOutlined, PartitionOutlined, QuestionCircleOutlined } from '@ant-design/icons-vue';
import { selectTeamMember } from '@/api/contract';
import { selectCreatePlanById, updateToPlanApproval, updateCreatePlan, updateToEndPlan, updatePlanNode } from '@/api/production';
import uploadImgs from '@/views/dataManagement/components/uploadImgs.vue';
import getFieldRules from '@/utils/fieldRules';
import { selectBelongContract } from '@/api/officeApi';
import { useRouter } from 'vue-router';
import programHistoryDetailModal from './programHistoryDetailModal.vue';
import { selectApprovalItem } from '@/api/material';
import Cookies from "js-cookie"; // import { useStore } from 'vuex'

const columns = [{
  title: '序号',
  dataIndex: 'serialNo',
  width: 90,
  fixed: 'left',
  align: 'center',
  slots: {
    customRender: 'serialNo'
  }
}, {
  title: '工作内容',
  dataIndex: 'content',
  align: 'center',
  fixed: 'left',
  width: 290,
  slots: {
    customRender: 'content'
  }
}, {
  title: '计划启动时间',
  dataIndex: 'startTime',
  width: 160,
  align: "center",
  slots: {
    customRender: 'startTime'
  }
}, {
  title: '计划完成时间',
  dataIndex: 'endTime',
  width: 160,
  align: "center",
  slots: {
    customRender: 'endTime'
  }
}, {
  title: '关联计划',
  dataIndex: 'payNode',
  // width: 100,
  children: [{
    title: '上游',
    dataIndex: 'upperNo',
    width: 180,
    align: 'center',
    slots: {
      customRender: 'upperNo'
    }
  }, {
    title: '下游',
    dataIndex: 'lowerNo',
    width: 180,
    align: 'center',
    slots: {
      customRender: 'lowerNo'
    }
  }]
}, {
  title: '负责人',
  dataIndex: 'operaterId',
  align: 'center',
  width: 170,
  slots: {
    customRender: 'operaterId'
  }
}, {
  title: '操作',
  dataIndex: 'operation',
  width: 80,
  align: 'center',
  slots: {
    customRender: 'operation'
  }
}];
const columnsForExecutor = [{
  title: '序号',
  dataIndex: 'serialNo',
  width: 100,
  align: 'center',
  fixed: 'left',
  slots: {
    customRender: 'serialNo'
  }
}, {
  title: '工作内容',
  dataIndex: 'content',
  width: 290,
  align: 'center',
  fixed: 'left',
  slots: {
    customRender: 'content'
  }
}, {
  title: '计划启动时间',
  dataIndex: 'startTime',
  align: 'center',
  width: 160,
  slots: {
    customRender: 'startTime'
  }
}, {
  title: '计划完成时间',
  dataIndex: 'endTime',
  align: 'center',
  width: 160,
  slots: {
    customRender: 'endTime'
  }
}, {
  title: '关联计划',
  dataIndex: 'payNode',
  children: [{
    title: '上游',
    dataIndex: 'upperNo',
    width: 180,
    align: 'center',
    slots: {
      customRender: 'upperNo'
    }
  }, {
    title: '下游',
    dataIndex: 'lowerNo',
    width: 180,
    align: 'center',
    slots: {
      customRender: 'lowerNo'
    }
  }]
}, {
  title: '负责人',
  dataIndex: 'operaterId',
  align: "center",
  width: 170,
  slots: {
    customRender: 'operaterId'
  }
}, {
  title: '完成时间',
  width: 160,
  align: 'center',
  dataIndex: 'finishTime'
}, {
  title: '状态',
  dataIndex: 'showState',
  align: 'center',
  width: 80,
  slots: {
    customRender: 'showState'
  }
}, {
  title: '操作/完成证明',
  dataIndex: 'operation1',
  align: 'center',
  width: 110,
  slots: {
    customRender: 'operation1'
  }
}];
export default defineComponent({
  components: {
    ProfileOutlined,
    BarsOutlined,
    PartitionOutlined,
    uploadImgs,
    QuestionCircleOutlined,
    programHistoryDetailModal
  },
  props: {
    applyDetailData: {
      type: Object,
      default: () => {}
    },
    contractTypeName: {
      type: Array,
      default: []
    }
  },
  emits: ['closeApplyVisible'],

  setup(props, context) {
    // const store = useStore()
    const formRef = ref();
    const formState = reactive({
      planName: "",
      planTime: undefined,
      planStartTime: undefined,
      belongContract: undefined,
      planNodes: [{
        serialNo: undefined,
        content: undefined,
        startTime: undefined,
        endTime: undefined,
        upperNo: [],
        lowerNo: [],
        operaterId: []
      }],
      fileUrl: [],
      // approvalProcedureDtos:[], //审批流程
      approvalDtos: [],
      hyApproachApprovals: [],
      content: null,
      updateList: [],
      itemId: null
    });
    const {
      validateTime,
      signFileurl
    } = getFieldRules();
    const rules = {
      planName: [{
        required: true,
        message: '计划名称不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      planTime: [{
        required: true,
        message: '计划完成时间不能为空',
        trigger: 'change',
        validator: validateTime
      }],
      planStartTime: [{
        required: true,
        message: '计划开始时间不能为空',
        trigger: 'change',
        validator: validateTime
      }],
      fileUrl: [{
        required: true,
        validator: signFileurl,
        type: 'array',
        // trigger: 'change',
        trigger: formState.fileUrl.length == 0
      }],
      content: [{
        required: true,
        message: '审批意见不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      itemId: [{
        required: true,
        message: '审批流程线不能为空',
        type: 'number',
        trigger: 'change'
      }]
    };

    const closeApplyVisible = () => {
      formRef.value.resetFields();

      if (props.applyDetailData.state == 98) {
        context.emit('closeApplyVisible', {
          isClose: false,
          state: 2
        });
      } else {
        context.emit('closeApplyVisible', {
          isClose: false,
          state: 1
        });
      }
    };

    const isDisabledForCl = computed(() => {
      if (props.applyDetailData.state === 0) {
        return true;
      } else if (props.applyDetailData.state == 1) {
        return true;
      } else if (props.applyDetailData.state == 99) {
        return false;
      } else if (props.applyDetailData.state == 98) {
        return true;
      }
    });
    const isDisabledForSp = computed(() => {
      if (props.applyDetailData.state === 0) {
        return true;
      } else if (props.applyDetailData.state == 1) {
        return true;
      } else if (props.applyDetailData.state == 99) {
        return true;
      } else if (props.applyDetailData.state == 98) {
        return true;
      }
    });

    const getData = async key => {
      const res = await selectCreatePlanById(key);

      try {
        Object.assign(formState, res.data);
        console.log(formState);
        formState.planNodes.forEach(item => {
          item.upperNo = item.upperNo == '' ? [] : item.upperNo.split(',');
          item.lowerNo = item.lowerNo == '' ? [] : item.lowerNo.split(',');
          item.operaterId = JSON.parse(item.operaterId);
        });
        let arr = [];
        formState.updateList.forEach(item => {
          arr.push({
            value: item.updateTime,
            label: item.updateTime
          });
        });
        applyHistoryDetailData.updateTimeOptions = arr;
        console.log(formState);
      } catch (error) {
        console.log('计划详情:', error);
      }
    }; //岗位及人员


    const options = ref([]);

    const getJobCascade = async () => {
      let res = await selectTeamMember();

      if (res.code === 200) {
        // console.log(res)
        options.value = res.data; // console.log(options.value)
      }
    };

    const onDelete = key => {
      if (formState.planNodes.length == 1) {
        _message.error('计划节点不能为空');

        return;
      }

      formState.planNodes.splice(key, 1);
    };

    const handleAdd = () => {
      const newData = {
        serialNo: undefined,
        content: undefined,
        startTime: undefined,
        endTime: undefined,
        upperNo: [],
        lowerNo: [],
        operaterId: []
      };
      formState.planNodes.push(newData);
    }; //所有序号


    const allSerialNo = computed(() => val => {
      let arr = [];
      formState.planNodes.forEach(item => {
        if (item.serialNo && item.serialNo !== val) {
          arr.push({
            value: item.serialNo,
            label: item.serialNo
          });
        }
      });
      return arr;
    }); //审批

    const handlePassOrReject = async num => {
      if (!formState.content) {
        _message.error('审批意见不能为空！');

        return;
      }

      const param = {
        planId: formState.planId,
        content: formState.content,
        state: num,
        belongProject: formState.belongProject
      };
      const res = await updateToPlanApproval(param);

      if (res.code == 200) {
        _message.success('审批成功！');

        context.emit('closeApplyVisible', {
          isClose: false,
          state: 2
        });
      } else {
        _message.error(res.message);
      }
    }; //审批员审批相关


    const getCurrent = arr => {
      const newArr = arr.filter(item => item.status == 1);
      return newArr.length - 1;
    };

    const setRoleType = num => {
      switch (num) {
        case 0:
          return "申请人";

        case 1:
          return "审批责任人";

        case 2:
          return "库管员";

        case 3:
          return "其他审批人";

        case 4:
          return "财务审批人";
      }
    }; //设置步骤条样式


    const setStatus = val => {
      const isError = val.some(item => item.isReject === 1);

      if (isError) {
        return 'error';
      }
    };

    const loading = ref(false); //修改

    const handleOk = () => {
      // console.log(formState)
      formRef.value.validate().then(async () => {
        let serialNoIsOk = findSerialNoIsOk(formState.planNodes, formState.planNodes.length - 1);

        if (serialNoIsOk) {
          _message.error('序号不能重复');

          return;
        }

        let dateIsOk = false;
        let dateNoArr = [];
        let dateIsOk1 = false;
        let dateNoArr1 = [];
        let upperNoIsOk = false;
        let nober = [];
        formState.planNodes.forEach(item => {
          if (new Date(item.startTime) > new Date(item.endTime)) {
            dateIsOk = true;
            dateNoArr.push(item.serialNo);
          }

          if (new Date(item.endTime) > new Date(formState.planTime)) {
            dateIsOk1 = true;
            dateNoArr1.push(item.serialNo);
          }

          for (let i = 0; i < item.upperNo.length; i++) {
            for (let j = 0; j < item.lowerNo.length; j++) {
              if (item.upperNo[i] === item.lowerNo[j]) {
                upperNoIsOk = true;
                nober.push(item.serialNo);
                return;
              }
            }
          }
        });

        if (dateIsOk) {
          _message.error(`序号为${dateNoArr.join(',')}的计划计划完成时间不能晚于计划启动时间`);
        }

        if (dateIsOk1) {
          _message.error(`序号为${dateNoArr1.join(',')}的计划计划完成时间不能晚于总计划完成时间`);
        }

        if (upperNoIsOk) {
          _message.error(`序号为${nober.join(',')}的计划上游序号和下游序号不能重复`);
        }

        if (dateIsOk || dateIsOk1 || upperNoIsOk) {
          return;
        } // let obj = {}
        // Object.assign(obj,formState)


        let obj = JSON.parse(JSON.stringify(formState));
        obj.belongContract = obj.belongContract ? obj.belongContract : null;
        obj.planNodes.forEach(item => {
          item.upperNo = item.upperNo.join(',');
          item.lowerNo = item.lowerNo.join(',');
          item.operaterId = JSON.stringify(item.operaterId);
        });
        console.log(1111111, obj);
        loading.value = true;
        let res = await updateCreatePlan(obj);

        if (res.code === 200) {
          _message.success(res.message);

          formRef.value.resetFields();
          context.emit('closeApplyVisible', {
            isClose: false,
            state: 2
          });
        } else {
          _message.error(res.message);

          loading.value = false;
        }

        loading.value = false;
      }).catch(error => {
        console.log('error', error);
      });
    };

    const findSerialNoIsOk = (arr, i) => {
      if (i <= 0) {
        return false;
      } else {
        if (formState.planNodes[i].serialNo !== formState.planNodes[i - 1].serialNo) {
          return findSerialNoIsOk(arr, i - 1);
        } else {
          return true;
        }
      }
    }; //申请人终止


    const handlePassOrRejectForSQR = async num => {
      const param = {
        id: formState.planId
      };
      const res = await updateToEndPlan(param);

      if (res.code == 200) {
        _message.success(res.data);

        context.emit('closeApplyVisible', {
          isClose: false,
          state: 2
        });
      } else {
        _message.error(res.data);
      }
    }; //负责人修改


    const handleUpdatePlanNode = async (state, id, fileUrl, isUpload) => {
      if (state == 2 && !isUpload) {
        _message.error('请先上传证明！');

        return;
      }

      const param = {
        id,
        planId: formState.planId,
        state,
        fileUrl
      };
      const res = await updatePlanNode(param);

      if (res.code == 200) {
        _message.success(res.message);

        getData(props.applyDetailData.key); // context.emit('closeApplyVisible', {isClose:false,state:2})
      } else {
        _message.error(res.message);
      }
    }; //上传证明


    const isShowCertificate = ref(false);
    const isShowUploadBtn = ref(false);
    const uploadLoading = ref(false);
    const certificateFileUrl = ref([]);
    const certificatePlanId = ref(null);

    const showCertificate = (num, fileUrl, id) => {
      isShowCertificate.value = true;
      certificateFileUrl.value = fileUrl ? JSON.parse(fileUrl) : [];
      certificatePlanId.value = id;

      if (num === 1) {
        isShowUploadBtn.value = true;
      } else {
        isShowUploadBtn.value = false;
      }
    };

    const handleUpdateCertificate = async () => {
      if (certificateFileUrl.value.length === 0) {
        _message.error('请先上传证明再提交！');

        return;
      }

      const param = {
        id: certificatePlanId.value,
        planId: formState.planId,
        state: 1,
        fileUrl: JSON.stringify(certificateFileUrl.value)
      };
      uploadLoading.value = true;
      const res = await updatePlanNode(param);

      if (res.code == 200) {
        _message.success(res.message);

        isShowCertificate.value = false; // context.emit('closeApplyVisible', {isClose:false,state:2})

        uploadLoading.value = false;
        getData(props.applyDetailData.key);
      } else {
        _message.error(res.message);

        uploadLoading.value = false;
      }
    };

    const getAdjuncts1 = val => {
      // console.log('getAdjuncts',val)
      certificateFileUrl.value = val;
    }; //归属合同查询


    const belongContractOptions = ref([]);

    const getBelongContract = async () => {
      let res = await selectBelongContract();

      if (res.code === 200) {
        belongContractOptions.value = [];
        if (res.data) res.data.forEach(item => {
          belongContractOptions.value.push({
            value: item.contractName,
            label: item.contractName
          });
        });
      } else {
        _message.error(res.message);
      }
    };

    const router = useRouter();

    const goApproval = () => {
      router.push('/approval-process/contract-approve');
    }; //查看详情


    const handleHistory = async () => {
      applyHistoryDetailData.state = 0;
      applyHistoryDetailData.key = props.applyDetailData.key;
      applyHistoryDetailData.applyVisible = true;
    }; //详情


    const applyHistoryDetailVisible = ref(false);
    const applyHistoryDetailData = reactive({
      state: 0,
      // data:{}
      key: null,
      applyVisible: false,
      updateTimeOptions: []
    }); //关闭详情页

    const closeApplyDetailVisible = val => {
      applyHistoryDetailData.applyVisible = val.isClose;
    }; //所属项目查询


    const projectOptions = ref([]);

    const getApprovalItem = async () => {
      const param = {
        type: 12,
        belongProject: Number(Cookies.get("belongProject"))
      };
      let res = await selectApprovalItem(param);

      if (res.code === 200) {
        projectOptions.value = [];

        if (res.data.approvalItems && res.data.approvalItems.length) {
          res.data.approvalItems.forEach(item => {
            projectOptions.value.push({
              value: item.id,
              label: item.itemName
            });
          });
        }
      } else {
        _message.error(res.message);
      }
    };

    onMounted(() => {
      getData(props.applyDetailData.key);
      getJobCascade();
      getBelongContract();
      getApprovalItem();
    });
    return {
      formRef,
      formState,
      rules,
      isDisabledForCl,
      isDisabledForSp,
      closeApplyVisible,
      options,
      columns,
      onDelete,
      handleAdd,
      handlePassOrReject,
      getCurrent,
      setRoleType,
      setStatus,
      handleOk,
      loading,
      allSerialNo,
      handlePassOrRejectForSQR,
      columnsForExecutor,
      handleUpdatePlanNode,
      isShowCertificate,
      showCertificate,
      isShowUploadBtn,
      uploadLoading,
      certificateFileUrl,
      getAdjuncts1,
      handleUpdateCertificate,
      belongContractOptions,
      propsCascader: {
        multiple: true
      },
      goApproval,
      closeApplyDetailVisible,
      applyHistoryDetailVisible,
      applyHistoryDetailData,
      handleHistory,
      projectOptions
    };
  }

});