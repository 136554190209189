import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import { ProfileOutlined, BarsOutlined } from '@ant-design/icons-vue';
import { defineComponent, ref, reactive, computed, onMounted } from 'vue';
import getFieldRules from '@/utils/fieldRules';
import { selectBelongContract } from '@/api/officeApi';
import { insertCreatePlan } from '@/api/production';
import { selectTeamMember } from '@/api/contract';
import { selectApprovalItem } from '@/api/material';
import Cookies from "js-cookie";
const columns = [{
  title: '序号',
  dataIndex: 'serialNo',
  width: 90,
  align: "center",
  slots: {
    customRender: 'serialNo'
  },
  fixed: 'left'
}, {
  title: '工作内容',
  dataIndex: 'content',
  width: 290,
  fixed: 'left',
  slots: {
    customRender: 'content'
  },
  align: "center"
}, {
  title: '计划启动时间',
  dataIndex: 'startTime',
  width: 160,
  align: "center",
  slots: {
    customRender: 'startTime'
  }
}, {
  title: '计划完成时间',
  dataIndex: 'endTime',
  width: 160,
  align: "center",
  slots: {
    customRender: 'endTime'
  }
}, {
  title: '关联计划',
  dataIndex: 'payNode',
  width: 200,
  children: [{
    title: '上游',
    dataIndex: 'upperNo',
    width: 100,
    align: "center",
    slots: {
      customRender: 'upperNo'
    }
  }, {
    title: '下游',
    dataIndex: 'lowerNo',
    width: 100,
    align: "center",
    slots: {
      customRender: 'lowerNo'
    }
  }]
}, {
  title: '负责人',
  dataIndex: 'operaterId',
  align: "center",
  width: 170,
  slots: {
    customRender: 'operaterId'
  }
}, {
  title: '操作',
  dataIndex: 'operation',
  width: 60,
  align: "center",
  slots: {
    customRender: 'operation'
  }
}];
export default defineComponent({
  components: {
    ProfileOutlined,
    BarsOutlined
  },
  props: {
    applyVisible: {
      type: Boolean,
      default: false
    }
  },
  emits: ['closeApplyVisible'],

  setup(props, context) {
    const formRef = ref();

    const closeApplyVisible = () => {
      formRef.value.resetFields();
      context.emit('closeApplyVisible', {
        isClose: false,
        state: 1
      });
    };

    const formState = reactive({
      planName: "",
      planStartTime: undefined,
      planTime: undefined,
      belongContract: undefined,
      planNodes: [{
        serialNo: undefined,
        content: undefined,
        startTime: undefined,
        endTime: undefined,
        upperNo: [],
        lowerNo: [],
        operaterId: []
      }],
      itemId: null
    });
    const {
      validateTime
    } = getFieldRules();
    const rules = {
      planName: [{
        required: true,
        message: '计划名称不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      planStartTime: [{
        required: true,
        message: '计划开始时间不能为空',
        trigger: 'change',
        validator: validateTime
      }],
      planTime: [{
        required: true,
        message: '计划完成时间不能为空',
        trigger: 'change',
        validator: validateTime
      }],
      itemId: [{
        required: true,
        message: '审批流程线不能为空',
        type: 'number',
        trigger: 'change'
      }]
    };
    const loading = ref(false); //提交

    const handleOk = () => {
      console.log(formState);
      formRef.value.validate().then(async () => {
        let serialNoIsOk = findSerialNoIsOk(formState.planNodes, formState.planNodes.length - 1);

        if (serialNoIsOk) {
          _message.error('序号不能重复');

          return;
        }

        let dateIsOk = false;
        let dateNoArr = [];
        let dateIsOk1 = false;
        let dateNoArr1 = [];
        let upperNoIsOk = false;
        let nober = [];
        formState.planNodes.forEach(item => {
          if (new Date(item.startTime) > new Date(item.endTime)) {
            dateIsOk = true;
            dateNoArr.push(item.serialNo);
          }

          if (new Date(item.endTime) > new Date(formState.planTime)) {
            dateIsOk1 = true;
            dateNoArr1.push(item.serialNo);
          }

          for (let i = 0; i < item.upperNo.length; i++) {
            for (let j = 0; j < item.lowerNo.length; j++) {
              if (item.upperNo[i] === item.lowerNo[j]) {
                upperNoIsOk = true;
                nober.push(item.serialNo);
                return;
              }
            }
          }
        });

        if (dateIsOk) {
          _message.error(`序号为${dateNoArr.join(',')}的计划计划完成时间不能晚于计划启动时间`);
        }

        if (dateIsOk1) {
          _message.error(`序号为${dateNoArr1.join(',')}的计划计划完成时间不能晚于总计划完成时间`);
        }

        if (upperNoIsOk) {
          _message.error(`序号为${nober.join(',')}的计划上游序号和下游序号不能重复`);
        }

        if (dateIsOk || dateIsOk1 || upperNoIsOk) {
          return;
        } // let obj = {}
        // Object.assign(obj,formState)


        let obj = JSON.parse(JSON.stringify(formState));
        obj.belongContract = obj.belongContract ? obj.belongContract : null;
        obj.planNodes.forEach(item => {
          item.upperNo = item.upperNo.join(',');
          item.lowerNo = item.lowerNo.join(',');
          item.operaterId = JSON.stringify(item.operaterId);
        });
        console.log(1111111, obj);
        loading.value = true;
        let res = await insertCreatePlan(obj);

        if (res.code === 200) {
          _message.success(res.message);

          formRef.value.resetFields();
          context.emit('closeApplyVisible', {
            isClose: false,
            state: 2
          });
        } else {
          _message.error(res.message);

          loading.value = false;
        }

        loading.value = false;
      }).catch(error => {
        loading.value = false;
        console.log('error', error);
      });
    };

    const findSerialNoIsOk = (arr, i) => {
      if (i <= 0) {
        return false;
      } else {
        if (formState.planNodes[i].serialNo !== formState.planNodes[i - 1].serialNo) {
          return findSerialNoIsOk(arr, i - 1);
        } else {
          return true;
        }
      }
    };

    const onDelete = key => {
      if (formState.planNodes.length == 1) {
        _message.error('计划节点不能为空');

        return;
      }

      formState.planNodes.splice(key, 1);
    };

    const handleAdd = () => {
      const newData = {
        serialNo: undefined,
        content: undefined,
        startTime: undefined,
        endTime: undefined,
        upperNo: [],
        lowerNo: [],
        operaterId: []
      };
      formState.planNodes.push(newData);
    }; //归属合同查询


    const belongContractOptions = ref([]);

    const getBelongContract = async () => {
      let res = await selectBelongContract();

      if (res.code === 200) {
        belongContractOptions.value = [];
        if (res.data) res.data.forEach(item => {
          belongContractOptions.value.push({
            value: item.contractName,
            label: item.contractName
          });
        });
      } else {
        _message.error(res.message);
      }
    }; //岗位及人员


    const options = ref([]);

    const getJobCascade = async () => {
      let res = await selectTeamMember();

      if (res.code === 200) {
        console.log(res);
        options.value = res.data; // console.log(options.value)
      }
    }; //所有序号


    const allSerialNo = computed(() => val => {
      let arr = [];
      formState.planNodes.forEach(item => {
        if (item.serialNo && item.serialNo !== val) {
          arr.push({
            value: item.serialNo,
            label: item.serialNo
          });
        }
      });
      return arr;
    }); //所属项目查询

    const projectOptions = ref([]);

    const getApprovalItem = async () => {
      const param = {
        type: 12,
        belongProject: Number(Cookies.get("belongProject"))
      };
      let res = await selectApprovalItem(param);

      if (res.code === 200) {
        projectOptions.value = [];

        if (res.data.approvalItems && res.data.approvalItems.length) {
          res.data.approvalItems.forEach(item => {
            projectOptions.value.push({
              value: item.id,
              label: item.itemName
            });
          });
        }
      } else {
        _message.error(res.message);
      }
    };

    onMounted(() => {
      getBelongContract();
      getJobCascade();
      getApprovalItem();
    });
    return {
      closeApplyVisible,
      formRef,
      formState,
      rules,
      handleOk,
      loading,
      handleAdd,
      onDelete,
      validateTime,
      belongContractOptions,
      options,
      columns,
      allSerialNo,
      propsCascader: {
        multiple: true
      },
      projectOptions
    };
  }

});